<template>
  <div class="box-layout">
    <HeaderFiveEpitonet>
      <img slot="logo" src="../../assets/img/logo/global-logo.jpg" />
    </HeaderFiveEpitonet>

    <!-- Start Portfolio Details Area -->
    <div class="rf-portfolio-details section-ptb-xl">
      <v-container class="container">
        <v-row>
          <v-col sm="6" cols="12" class="pr--60 pr-md-15 pr-sm-15">
            <div class="rp-port-thumb">
              <div class="port-single-thumb">
                <img
                  src="../../assets/img/567x600px/567x600px-SmartHome.jpg"
                  alt="portfolio"
                />
              </div>

            </div>
          </v-col>
          <v-col sm="6" cols="12">
            <div class="rp-port-details-inner sticky-top">
              <div class="content">
                <h3>Okosotthon</h3>
                <p>
                  Az okos otthon az igényeinkhez tökéletesen idomuló automatizált rendszer jelent, melyben az elektromos eszközök a szokásainkhoz alkalmazkodva működnek, a mi kényelmünket szolgálva. Lehetőségünk nyílik az elektromos eszközök működését automatizálni, szabályozni, vagy akár távolról ellenőrizni, vezérelni. 

Egy okos otthon fő célja a kényelem, biztonság, és az energia optimalizálás fokozása. A felhasználói igények változatosságára kijelenthetjük, hogy nem létezik két egyforma okos otthon. Éppen ezért egy személyes konzultáció alkalmával felmérjük az igényeket, és bemutatjuk, hogy mennyire változatos és sokféle felhasználási lehetőség közül válogathat.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area -->

    <Footer />
  </div>
</template>

<script>
  import HeaderFiveEpitonet from "../../components/header/HeaderFourEpitonet";
  import Footer from "../../components/footer/Footer";
  export default {
    components: {
      HeaderFiveEpitonet,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .portfolio-details-page .-rn-footer {
    background: #f9f9f9;
  }
</style>
